@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 fonts
 */
/* @import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap'); */

:root {
  --clr-champagne: #ffebe0;
  --clr-beige: #e4eed3;
  --clr-antique: #f9f4ec;
  --clr-linen: #fff1e6;
  --clr-old-lavender: #807182;
  --clr-old-lavender-dark: hsl(293, 7%, 40%);
  --clr-pastel-mint: #e2ece9;
  --clr-pastel-tea: #f1ffdd;
  /* --clr-pastel-blue: #bee1e6; */
  /* --clr-pastel-magnolia: #eae4e9; */
  --clr-pastel-magnolia: #faf4fa;
  /* --clr-pastel-magnolia: #fff8f4; */
  --clr-pastel-ghost-white: #f4f7ff;
  --clr-pastel-lavender: #dde6fd;
  --clr-pastel-lavender-light: #ecf1fe;
  --clr-pastel-lavender-dark: #cedbfd;
  --clr-pastel-lavender-darkest: #bfd0fc;
  --clr-pastel-dark-blue: #94b0ff;
  --clr-pastel-blue: #9eb8ff;
  --clr-sand: #ffe8d6;
  --clr-brass: #cb997e;
  --clr-melon: #f5b69b;
  --clr-apricot: #fbc4ab;
  --clr-peach: #ffdab9;
  --clr-bisque: #ffe5cd;
  --clr-fb-blue: hsl(220, 46%, 48%);
  --clr-fb-blue-light: hsl(220, 46%, 65%);
  --clr-yt-red: hsl(0, 100%, 50%);
  --clr-yt-red-light: hsl(0, 100%, 75%);
  --clr-black: #333;
  --clr-light-black: #444;
  --clr-white: #fff;
  --ff-primary: 'Catamaran', sans-serif;
  /* --ff-cursive: 'Courgette', cursive; */
  --ff-cursive: 'Dancing Script', cursive;
  --ff-secondary: 'Grand Hotel', cursive;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --spacing-smaller: 0.1rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(152, 152, 152, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.85);
  /* --text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.79); */
}

html {
  scroll-behavior: smooth;
  /* !for scrolltotop to work */
  height: auto;
  overflow: auto;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-pastel-ghost-white);
  /* color: #333; */
  color: var(--clr-old-lavender);
  line-height: 1.5;
  font-size: 0.875rem;
  /* IMPORTANT */
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
ul {
  text-align: left;
}
/*! ul li {
  list-style-type: none;
} */
img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  /* *.83 */
  font-size: 3rem;
  font-size: 2.7rem;
  font-size: 2.5rem;
  color: var(--clr-old-lavender-dark);
}

h2 {
  font-size: 2rem;
  font-size: 1.8rem;
  font-size: 1.65rem;
}
h3 {
  font-size: 1.5rem;
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
    font-size: 3.6rem;
  }

  h2 {
    font-size: 2.5rem;
    font-size: 2.25rem;
    /* font-size: 2.125rem; */
  }
  h3 {
    /* font-size: 2rem; */
    /* font-size: 1.8rem; */
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }

  /* h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  } */
}

/* font */
.special-font {
  font-family: var(--ff-cursive);
}

/* BACKGROUND COLORS */
.linen {
  background-color: var(--clr-linen);
}
.lavender {
  background-color: var(--clr-pastel-lavender);
}
.light-lavender {
  background-color: var(--clr-pastel-lavender-light);
}
.mint {
  background-color: var(--clr-pastel-mint);
}
.magnolia {
  background-color: var(--clr-pastel-magnolia);
  background-color: var(--clr-pastel-ghost-white);
}
.blue {
  background-color: var(--clr-pastel-blue);
}
.tea {
  background-color: var(--clr-pastel-tea);
}

/* GENERAL STYLES */

.card {
  /* 0.5 */
  /* background-color: rgba(255, 255, 255, 0.75); */
  /* padding: 2rem; */
  box-shadow: var(--light-shadow);
}

.bio-card {
  max-width: 50rem;
  margin: 0 auto;
}

.bio-card p {
  text-indent: 1rem;
}

.bio-card p::first-letter {
  font-family: var(--ff-cursive);
  font-size: x-large;
}
p::first-letter {
  font-family: var(--ff-cursive);
  font-size: x-large;
}
p {
  text-indent: 1rem;
}

.btn {
  text-transform: uppercase;
  color: var(--clr-white);
  /* padding: 0.375rem 0.75rem; */
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  /* font-size: 0.875rem; */
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  outline: 0.125rem solid var(--clr-pastel-blue);
  outline-offset: 0.25rem;
  outline-color: var(--clr-pastel-blue);
  background: var(--clr-pastel-blue);

  font-size: 1.1rem;
  padding: 0.4rem 1.25rem;
  transition: var(--transition);
}

.btn:hover {
  background-color: var(--clr-pastel-dark-blue);
  outline-color: var(--clr-pastel-dark-blue);
}
/* 
.facebook-btn {
  outline-color: var(--clr-pastel-blue);
  background: var(--clr-pastel-blue);
}
.facebook-btn:hover {
  background-color: var(--clr-pastel-dark-blue);
}
.youtube-btn {
  outline-color: var(--clr-pastel-blue);
  background: var(--clr-pastel-blue);
}
.youtube-btn:hover {
  background-color: var(--clr-pastel-dark-blue);
} */

.btn i {
  padding-right: 0.5rem;
}

/* clearfix hack */
/* .clearfix::after,
.clearfix::before {
  content: '';
  clear: both;
  display: table;
} */

.section-title h3 {
  font-family: var(--ff-secondary);
  color: var(--clr-primary);
}

.section-title {
  margin-bottom: 2rem;
}

.section-center {
  /* padding: 4rem 0; */
  /* padding: 4rem 2rem; */
  width: 85vw;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  letter-spacing: var(--spacing-smaller);
}
.initial-section {
  padding-top: 7.25rem;
  /*! margin-top: 3.75rem; */
}

.questions-container {
  width: 100vw;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.overview .flex-container {
  /* ! */
  margin-bottom: 3.5rem;
  gap: 2rem 4rem;
}
/* .overview .section-text {
  max-width: 28rem;
  max-width: 40rem;
} */
.overview .extra-margin {
  margin-bottom: 5rem;
}
/* 
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
    padding: 4rem 1rem;
  }
} */

/* ANIMATIONS */
@keyframes show {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);

    opacity: 1;
  }
}

/* ! nav was here */

/* HEADER */
/* body */
/* HOME */
.header {
  /* ! */
  min-height: 100vh;
  min-height: 100dvh;

  /* 0.6 */
  /* 0.4 */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./assets/images/background/music-flowers.jpg') center/cover no-repeat
      fixed;

  /* ! */
  /* position: relative; */
  /* animation IMPORTANT*/
  overflow-x: hidden;

  /* ??? */
  padding: 2rem 1rem;
  padding-top: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  gap: 1rem;
}

/* .header-content {
  border: 1px solid red;
} */

/* Fixed background for tablet and mobile */
@media screen and (max-width: 1024px) {
  .header {
    /* background: linear-gradient(var(--clr-pastel-2), hsl(22, 43%, 78%)); */
    background-attachment: scroll;
  }
}

/* !nav items was here */

/* .banner-container {
  text-align: center;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
/* 
.absolute {
  position: relative;
} */

.center-container {
  width: 85vw;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  /* height: 100vh; */
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  letter-spacing: var(--spacing-smaller);
  border: 1px solid yellow;
}
.flex-center {
  /* position: absolute; */
  /* top: 4rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* justify-content: baseline; */
  /* height: 60vh; */
  /* min-height: calc(100vh - 6.75rem); */

  /* height: 100vh; */
  /* min-height: calc(100vh - 18rem); */
  border: 1px solid red;
}

.flex-container {
  /* padding: 4rem 0; */
  /* width: 85vw; */
  /* margin: 0 auto; */
  /* max-width: 1170px; */
  /* margin-top: 3rem; */

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  /* justify-content: space-around;
  justify-content: space-between; */
  justify-content: center;
  gap: 1rem 4rem;
  /* ! */
  /* margin-bottom: 2rem; */
}
.flex-reverse {
  flex-direction: row-reverse;
}

@media screen and (min-width: 826px) {
  .flex-container {
    justify-content: space-between;
  }
}
@media screen and (min-width: 1100px) {
  .flex-container {
    justify-content: space-around;
  }
}

.profile-pic-container {
  padding: 0.5rem;
  /* border-radius: 50%;
  width: 200px;
  height: 200px; */
  /* clip-path: ellipse(26% 51%); */
  background: white;
  box-shadow: var(--dark-shadow);
  /* width: 200px;
  height: 200px; */
  /* border: 0.5rem solid white; */
}

.hero-btn-container {
  text-align: center;
}

.hero-btn {
  cursor: pointer;
  transition: var(--transition);
  /* padding: 0.25rem 1rem; */
  padding: 0 1rem;
  color: white;
  background: none;
  /*! border: 0.15rem solid white; */
  /* font-size: 2rem; */
  font-size: 2.5rem;
}

.hero-btn:hover {
  color: var(--clr-pastel-lavender-darkest);
  /* !border-color: var(--clr-pastel-lavender-darkest); */
  /* color: var(--clr-pink);
  border-color: var(--clr-pink); */
}

.hero-btn-text {
  color: white;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: var(--spacing-smaller);
}

.logo-container {
  /* max-height: 300px; */
  max-height: 20rem;
  max-width: 40rem;
  overflow: hidden;
}

.logo-container img {
  /* max-height: 300px; */
  max-height: 20rem;
  max-width: 40rem;
}

.banner-img {
  max-width: 30rem;
  /* max-height: 20rem; */
  /* TEST */
  /* margin: 0 auto; */
}

.profile-img {
  /* max-width: 20rem; */
  /* max-width: 18rem; */
  max-width: 14rem;
}
@media screen and (min-width: 850px) {
  .profile-img {
    max-width: 16rem;
  }
}
@media screen and (min-width: 1150px) {
  .profile-img {
    max-width: 18rem;
  }
}

.banner-img img {
  /* border-radius: 50%;*/
  /* width: 200px;
  height: 200px; */
  object-fit: cover;

  /* max-height: 20rem; */
  /* clip-path: ellipse(25% 50%); */
}

.btn-container {
  display: flex;
  justify-content: center;
}
.banner-btn {
  font-size: 1.1rem;
  padding: 0.4rem 1.25rem;
}
.banner-img {
  padding: 2rem 0;
}

.banner-text p {
  letter-spacing: var(--spacing-smaller);
  margin: 0 auto;
  text-indent: 1rem;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  color: whitesmoke;
  max-width: 35rem;
  animation: appear 1.5s linear 1;
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
  text-align: center;
}

.alt-text {
  font-family: var(--ff-primary);
}

/* .center-text {
  max-width: 50em;
  margin: 0 auto;
} */

/* ******* VIDEOS ********* */

/* TEST */
/* @media screen and (min-width: 992px) {
  .banner {
    float: left;
    width: 60%;
  }
  .banner-img {
    float: left;
    width: 40%;
  }
} */

/* OVERVIEW SECTION */

/* .section-center h2 {
  font-family: var(--ff-cursive);
  font-style: italic;
  font-size: 3.75rem;
} */

.text-center {
  text-align: center;
}
.overview ul {
  margin-left: 2rem;
}
.overview li {
  /* list-style-type: square; */
  padding-left: 1rem;
}
.overview h2 {
  /* color: var(--clr-light-black); */
  color: var(--clr-old-lavender-dark);
  /* font-family: var(--ff-cursive); */
}
.overview span {
  display: inline-block;
}
.overview span::first-letter {
  font-family: var(--ff-cursive);
}
.about-section a {
  color: var(--clr-old-lavender);
  text-decoration: underline;
}
.about-section a:hover {
  color: var(--clr-pastel-dark-blue);
}
.famer {
  margin-top: 5rem;
}
/* .overview p {
  max-width: 40rem;
} */

.section-text p {
  max-width: 30rem;
  font-size: large;
}

/* !contact section */

/* CAROUSEL */
.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  /* !! */
  width: 240px;
  height: 172px;
}

.img-slider-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 240px;
  height: 172px;
}

.carousel-item {
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.carousel-item img {
  object-fit: cover;
}

/* IMAGE SLIDER */

.image-slider {
  display: flex;
  /* position: relative; */
  justify-content: center;
  align-items: center;
}
.slider-img {
  width: 240px;
  height: 172px;
  object-fit: cover;
}
@media screen and (min-width: 300px) {
  .img-slider-container {
    width: 19.6rem;
    height: 14rem;
  }
  /* .slider-img {
    width: 19.6rem;
    height: 14rem;
  } */
}
/* @media screen and (min-width: 480px) {
  .slider-img {
    width: 19.6rem;
    height: 14rem;
  }
} */

@media screen and (min-width: 1150px) {
  .img-slider-container {
    width: 28rem;
    height: 20rem;
  }
  /* .slider-img {
    width: 28rem;
    height: 20rem;
  } */
}
.slider-slide {
  opacity: 0.1;
  transition-duration: 3s ease;
}
.active-slide {
  opacity: 1;
  transition-duration: 3s;
}

/* IMAGE SLIDER */
.img-slider-frame {
  background: white;
  padding: 0.5rem;
  box-shadow: var(--dark-shadow);
}

.testimonial-img {
  max-width: 20rem !important;
  margin: 0 auto;
}
blockquote {
  font-family: Georgia, serif;
  font-style: italic;
  /* width: 500px; */
  /* margin: 0.25em 0; */
  /* padding: 0.35em 40px; */
  position: relative;
  color: #383838;
  /* color: var(--clr-old-lavender); */
}

blockquote:before {
  display: block;
  padding-left: 20px;
  content: '\201C';
  /* font-size: 80px; */
  font-size: 4.5rem;
  position: absolute;
  left: -40px;
  top: -40px;
  color: var(--clr-old-lavender);
}
.testimonial-text {
  max-width: 30rem;
  padding: 2rem 0;
}
.testimonial {
  max-width: 35rem;
  margin: 0 auto;
}

.testimonial p {
  /* font-size: large; */
  font-size: 1.1rem;
  /* text-align: justify; */
}
.testimonial h3 {
  text-transform: uppercase;
  font-size: 1.7rem;
}

.section-title {
  text-align: center;
}

/* QUESTIONS */

/* accordion */
.accordion-container {
  max-width: 50rem;
  /* margin: 0 auto; */

  /* cursor: pointer; */
  /* padding: 18px 0; */
  padding: 18px 1rem;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  /* color: #231f20; */
  color: var(--clr-old-lavender);
  max-width: 770px;
  display: block;
  margin: auto;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  padding: 1rem 0.5rem;
  padding-right: 0.75rem;
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  /* transition: var(--transition); */
  text-transform: uppercase;
  font-size: 1.55rem;
  font-size: 1.3rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing-smaller);
  transition: var(--transition);
  display: flex;
  justify-content: space-between;
}
.active-panel {
  /* max-height: fit-content; */
  max-height: 500px;
  /* transition: max-height 0.2s ease; */
  /* transition: max-height 1s ease-in-out; */
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  /* transition: var(--transition); */
  padding-bottom: 1rem;
}

.hidden-panel {
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  letter-spacing: var(--spacing-smaller);
  overflow: hidden;
  border-bottom: 0.1rem solid var(--clr-old-lavender);
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

/* LESSONS */
.lessons-info {
  /* !max-width: 35rem; */
  max-width: 38rem;
  /* ! */
  margin-bottom: 6rem;
}
ol > li::marker {
  font-weight: bold;
}
ol {
  margin-bottom: 0.2rem;
}
ol > li {
  margin-bottom: 0.1rem;
}

.highlight {
  text-decoration: underline;
  color: var(--clr-old-lavender-dark);
}

/* .lesson-link {
  color: var(--clr-pastel-dark-blue);
  font-weight: 300;
  font-size: 1.3rem;
} */

.lesson-img {
  max-width: 30rem;
  /* border: 1px solid red; */
}

/* ! */
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  border: 0.25rem var(--clr-pastel-ghost-white) solid;
  box-shadow: var(--dark-shadow);
}
.image2 {
  position: absolute;
  top: -45%;
  left: 55%;
  /* top: 250px;
  left: 250px; */
  width: 50%;
  /* top: 30px;
  left: 30px; */
  border: 0.25rem white solid;
  box-shadow: var(--dark-shadow);
}

.centered {
  margin-top: 2rem;
  text-align: center;
}

/* CONTACT-FORM */

.contact-form {
  /* max-width: 35rem; */
  /* width: 30rem; */
  width: 100%;
  transition: var(--transition);
  max-width: 30rem;
  /* max-width: 30rem; */
  padding: 1rem 1.5rem;
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
}

.contact-form h3 {
  text-align: center;
  text-transform: none;
  font-style: oblique;
}

.contact-form label {
  text-transform: uppercase;
}
.contact-form h3 {
  padding-top: 1.25rem;
}
.form-group {
  padding: 1rem 0;
}
.form-control {
  /* background: white; */
  background: transparent;
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 1.25rem;
  border-bottom: 0.1rem solid var(--clr-old-lavender);
  letter-spacing: var(--spacing-smaller);
  /* ! */
  /* box-shadow: var(--dark-shadow); */
  transition: var(--transition);
}
.form-control::placeholder {
  /* in some cases placeholder wont inherit ff */
  font-family: var(--ff-primary);
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: var(--spacing-smaller);
}
.form-control:hover {
  transform: scale(1.025);
  border-color: var(--clr-pastel-dark-blue);
}
.form-control:focus {
  outline: none;
  border-color: var(--clr-pastel-dark-blue);
}

.submit-btn {
  font-size: 1rem;
  margin-top: 2rem;
  display: block;
  /* color: var(--clr-old-lavender); */
  color: white;
  width: 100%;
  padding: 0.85rem 1rem;
  /* transition: var(--transition); */
}

.submit-btn:hover {
  outline-color: var(--clr-pastel-dark-blue);
  background: var(--clr-pastel-dark-blue);
  transform: scale(1.025);
}

/* VIDEOS */
/* !grid */

/* FOOTER */

/* footer */
.footer {
  height: 12rem;
  background: var(--clr-black);
  color: white;
  text-align: center;
}

.footer-text {
  padding-top: 4rem;
  /* text-transform: uppercase; */
  color: var(--clr-white);
  font-weight: 400;
  line-height: 2rem;
}
#intro {
  scroll-margin-top: 3.75rem;
}
.square-list li {
  list-style-type: square;
  margin-left: 1.5rem;
}
#overview {
  scroll-margin-top: 3.75rem;
}

/*  
!!old approach
.fullscreen-img {
  //width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}
*/

/* IMAGE SLIDER */

.image-slider {
  display: flex;
  /* position: relative; */
  justify-content: center;
  align-items: center;
}
.slider-img {
  width: 240px;
  height: 172px;
  object-fit: cover;
}
@media screen and (min-width: 300px) {
  .slider-img {
    width: 19.6rem;
    height: 14rem;
  }
}
/* @media screen and (min-width: 480px) {
  .slider-img {
    width: 19.6rem;
    height: 14rem;
  }
} */

@media screen and (min-width: 1150px) {
  .slider-img {
    width: 28rem;
    height: 20rem;
  }
}
.slider-slide {
  opacity: 0.1;
  transition-duration: 3s ease;
}
.active-slide {
  opacity: 1;
  transition-duration: 3s;
}

/* POPUP */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: white;
}
.popup-inner p::first-letter {
  font-family: var(--ff-primary);
}

.popup-btn-container {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
