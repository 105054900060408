.container {
  padding: 15px;
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images > li {
  position: relative;
  padding-top: 66%;
}

ul.images > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 567px) {
  ul.images {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
